import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import store, { awaitLiffInitialized } from "@/store";
import { getRole } from "@/api/auth";
import { getStudent } from "@/api/student";
import liff from "@line/liff/dist/lib";
import { pageViewLogEvent, setUserIdToAnalytics } from "@/api/analytics";
import { getDocIdsOfStudentRef } from "@/entities/student";
import { getSchoolConfig } from "@/api/school";

let authUnsubscribe: firebase.Unsubscribe | null = null;

function updateIsInLiff() {
  store.commit("SET_IS_IN_LIFF", liff.isInClient());
}

function updateIsInMingakuStudentApp() {
  store.commit("SET_IS_IN_MINGAKU_STUNDET_APP", true);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    beforeEnter(_, __, next) {
      if (__.name && __.name !== "login") {
        // ログイン画面からの遷移でない場合、学習履歴を再取得
        store.dispatch("getLearnings");
        store.dispatch("updateStudent");
        store.dispatch("getSubmissions");
        store.dispatch("getThreads");
      }
      if (store.state.isInLiff) {
        next("/learning");
      } else {
        next();
      }
    },
    meta: { pageType: "home" }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/auth/reader",
    name: "auth_reader",
    component: () => import("../views/AuthReader.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/analyze/future",
    name: "analyze_future",
    beforeEnter(_, __, next) {
      if (!store.state.isInLiff) {
        next("/");
        return;
      }
      store.commit("SET_LEARNING_PAGE_TYPE", "future");
      next();
    },
    component: () => import("../views/Learning.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/badge",
    name: "badge_list",
    component: () => import("../views/BadgeList.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/learning",
    name: "learning_history",
    beforeEnter(_, __, next) {
      if (!store.state.isInLiff) {
        next("/");
        return;
      }
      store.commit("SET_LEARNING_PAGE_TYPE", "history");
      next();
    },
    component: () => import("../views/Learning.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/learning_start",
    name: "learning_start",
    component: () => import("../views/LearningStart.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/learning/:learningId",
    name: "learning",
    component: () => import("../views/LearningDetail.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/learning/:learningId/timer",
    name: "learning_timer",
    component: () => import("../views/LearningTimer.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/learning/:learningId/reflection",
    name: "learning_reflection",
    component: () => import("../views/ReflectionForm.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/message",
    name: "message",
    component: () => import("../views/Message.vue"),
    beforeEnter(_, __, next) {
      if (store.state.isInLiff) {
        next("/learning");
      } else {
        next();
      }
    },
    meta: { pageType: "message" }
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/Setting.vue"),
    meta: { pageType: "setting" }
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("../views/Notification.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/room/form",
    name: "room_enter_form",
    component: () => import("../views/EnterForm.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/room/reservation",
    name: "room_reservation",
    component: () => import("../views/Reservation.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/submission/",
    name: "submission_list",
    component: () => import("../views/SubmissionList.vue"),
    beforeEnter(_, __, next) {
      if (store.state.isInLiff) {
        next();
      } else {
        next("/");
      }
    },
    meta: { pageType: "other" }
  },
  {
    path: "/submission/:submissionId",
    name: "submission_detail",
    component: () => import("../views/SubmissionDetail.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/thread/:threadId",
    name: "thread_detail",
    component: () => import("../views/ThreadDetail.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/todo",
    name: "todo",
    component: () => import("../views/Todo.vue"),
    meta: { pageType: "other" }
  },
  {
    path: "/originalInfo/:originalInfoIds*",
    name: "original_info",
    component: () => import("../views/OriginalInfo.vue"),
    meta: { pageType: "other" }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async to => {
  await awaitLiffInitialized(store);

  pageViewLogEvent(to.fullPath, to.path, to.name as string);

  updateIsInLiff();
  // クエリパラメータにauthのidTokenが渡された場合、idToekn認証を実行
  const { token, from } = to.query as { token: string; from: string };
  if (from === "mingaku_student_mobile_app") {
    updateIsInMingakuStudentApp();
  }
  if (token) {
    try {
      const callVerifyIdToken = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("verify_id_token");
      const { data } = await callVerifyIdToken({ token });
      const { customToken } = data;
      await firebase.auth().signInWithCustomToken(customToken);
    } catch (e) {
      // tokenが不正な場合、エラーをコンソールに出力
      console.log(e);
    }
  }

  if (authUnsubscribe !== null) {
    authUnsubscribe();
  }
  authUnsubscribe = firebase.auth().onAuthStateChanged(async function(user) {
    if (!user) {
      if (to.name === "login" || to.name === "auth_reader") {
        return;
      } else {
        store.commit("SET_REDIRECT", to.path);
        router.replace("/login");
        return;
      }
    }

    setUserIdToAnalytics(user.uid);

    if (!store.state.role) {
      store.commit("SET_LOADING", true);
      const role = await getRole(user.uid);
      if (!role) {
        store.commit("SET_LOADING", false);
        router.replace("/login");
        return;
      }
      store.commit("SET_ROLE", role);
      const docIds = getDocIdsOfStudentRef(role.data.ref);
      const [student, schoolConfig] = await Promise.all([
        getStudent(role.data.ref),
        getSchoolConfig(docIds[0])
      ]);

      store.commit("SET_LOADING", false);
      if (!student || !schoolConfig) {
        alert("生徒情報の取得に失敗しました。再度ログインし直してください");
        await store.dispatch("signOut");
        router.replace("/login");
        return;
      } else if (
        (student.data.recessTime && student.data.recessTime > 0) ||
        (schoolConfig.data.recessTime && schoolConfig.data.recessTime > 0)
      ) {
        alert("このアカウントは現在休会中であるため、利用することができません");
        await store.dispatch("signOut");
        router.replace("/login");
        return;
      }
      store.commit("SET_STUDENT", student);
      store.commit("SET_SCHOOL_CONFIG", schoolConfig);
      const classroomRef = student.ref.parent.parent;
      classroomRef && store.commit("SET_CLASSROOM_DOC_ID", classroomRef.id);
      const schoolRef = classroomRef?.parent.parent;
      schoolRef && store.commit("SET_SCHOOL_DOC_ID", schoolRef.id);
      store.dispatch("getSubmissions");
      store.dispatch("getThreads");
      store.dispatch("setMessageStream");
      store.dispatch("getLearnings");
      store.dispatch("getRooms");
      store.dispatch("getUnreadMessagesLength");
    }
    store.commit(
      "SET_IS_ANDROID",
      navigator.userAgent.toLowerCase().indexOf("android") !== -1
    );

    if (to.name === "login" || to.name === "auth_reader") {
      router.replace("/");
      return;
    }
  });
});

export default router;
