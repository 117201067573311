import { signOut } from "@/api/auth";
import {
  getSubmissionOfStudent,
  complementSubmissions
} from "@/api/submission";
import { getThreadsOfStudent, complementThreads } from "@/api/thread";
import { getLearningsOf } from "@/api/learning";
import {
  getMessages,
  getMessageStream,
  getUnreadMessagesLength
} from "@/api/message";
import { fetchRooms } from "@/api/room";
import { getStudent } from "@/api/student";
import { Learning } from "@/entities/learning";
import { convertToMessage, Message, MessageContent } from "@/entities/message";
import { Role } from "@/entities/role";
import { Room } from "@/entities/room";
import {
  getDocIdsOfStudent,
  getSchoolRefFromStudentRef,
  Student
} from "@/entities/student";
import { ComplementedSubmission } from "@/entities/submission";
import { ComplementedThread } from "@/entities/thread";
import router from "@/router";
import { createStore, Store } from "vuex";
import dayjs from "dayjs";
import { MiniApp } from "@/entities/mini_app";
import { getImageUrl, getMiniAppsOfSchool } from "@/api/mini_app";
import { SchoolConfig } from "@/entities/school";

const isMessageToIgnoreForStudent = (fromName: string) => {
  return fromName.includes("操作ログ");
};

export type LearningPageType = "future" | "history" | "none";

type AppState = {
  loading: boolean;
  loadText: string;
  redirect: string;
  initLiff: boolean;
  isInLiff: boolean;
  isInMingakuStudentApp: boolean;
  role: Role | null;
  student: Student | null;
  classroomDocId: string;
  schoolDocId: string;
  schoolConfig: SchoolConfig | null;
  submissions: ComplementedSubmission[];
  submissionsInitialized: boolean;
  submissionsError: string;
  miniAppList: MiniApp[];
  miniAppimageUrls: { [key: string]: string };
  threads: ComplementedThread[];
  threadsInitialized: boolean;
  threadsError: string;
  learnings: Learning[];
  learningLoading: boolean;
  learningLimit: number;
  learningFinished: boolean;
  learningPageType: LearningPageType;
  learning: Learning | null;
  messageStream: (() => void) | null;
  messages: Message[];
  messageLoading: boolean;
  messageFinished: boolean;
  unreadMessagesLength: number;
  messageLimit: number;
  rooms: Room[];
  roomLoaded: boolean;
  isAndroid: boolean;
};
const messageLimit = 20;
const learningLimit = 20;

export default createStore({
  state: {
    loading: false,
    loadText: "",
    redirect: "/",
    initLiff: false,
    isInLiff: false,
    isInMingakuStudentApp: false,
    role: null,
    student: null,
    classroomDocId: "",
    schoolDocId: "",
    schoolConfig: null,
    submissions: [],
    submissionsInitialized: false,
    submissionsError: "",
    miniAppList: [],
    miniAppimageUrls: {},
    threads: [],
    threadsInitialized: false,
    threadsError: "",
    learnings: [],
    learningLoading: true,
    learningLimit: learningLimit,
    learningFinished: false,
    learningPageType: "none",
    learning: null,
    messageStream: null,
    messages: [],
    messageLoading: false,
    messageFinished: false,
    unreadMessagesLength: 0,
    messageLimit: messageLimit,
    rooms: [],
    roomLoaded: false,
    isAndroid: false
  } as AppState,
  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_LOAD_TEXT(state, text: string) {
      state.loadText = text;
    },
    SET_REDIRECT(state, redirect: string) {
      state.redirect = redirect;
    },
    SET_INIT_LIFF(state, initLiff: boolean) {
      state.initLiff = initLiff;
    },
    SET_IS_IN_LIFF(state, isInLiff: boolean) {
      state.isInLiff = isInLiff;
    },
    SET_IS_IN_MINGAKU_STUNDET_APP(state, isInMingakuStudentApp: boolean) {
      state.isInMingakuStudentApp = isInMingakuStudentApp;
    },
    SET_ROLE(state, role: Role) {
      state.role = role;
    },
    SET_STUDENT(state, student: Student) {
      state.student = student;
    },
    SET_SCHOOL_CONFIG(state, schoolConfig: SchoolConfig) {
      state.schoolConfig = schoolConfig;
    },
    SET_SCHOOL_DOC_ID(state, schoolDocId: string) {
      state.schoolDocId = schoolDocId;
    },
    SET_CLASSROOM_DOC_ID(state, classroomDocId: string) {
      state.classroomDocId = classroomDocId;
    },
    SET_SUBMISSIONS(state, submissions: ComplementedSubmission[]) {
      state.submissions = submissions;
    },
    SET_SUBMISSIONS_INITIALIZED(state, initialized: boolean) {
      state.submissionsInitialized = initialized;
    },
    SET_SUBMISSIONS_ERROR(state, message: string) {
      state.submissionsError = message;
    },
    SET_APPS(state, miniAppList: MiniApp[]) {
      state.miniAppList = miniAppList;
    },
    SET_MINIAPPIMAGEURLS(state, miniAppimageUrls) {
      state.miniAppimageUrls = miniAppimageUrls;
    },
    SET_THREADS(state, threads: ComplementedThread[]) {
      state.threads = threads;
    },
    SET_THREADS_INITIALIZED(state, initialized: boolean) {
      state.threadsInitialized = initialized;
    },
    SET_THREADS_ERROR(state, message: string) {
      state.threadsError = message;
    },
    SET_LEARNINGS(state, learnings: Learning[]) {
      state.learnings = learnings;
    },
    SET_LEARNING_LOADING(state, loading: boolean) {
      state.learningLoading = loading;
    },
    SET_LEARNING_FINISHED(state, finished: boolean) {
      state.learningFinished = finished;
    },
    SET_LEARNING_PAGE_TYPE(state, type: LearningPageType) {
      state.learningPageType = type;
    },
    SET_LEARNING(state, learning: Learning | null) {
      state.learning = learning;
    },
    SET_MESSAGE_STREAM(state, stream: (() => void) | null) {
      state.messageStream = stream;
    },
    SET_MESSAGES(state, messages: Message[]) {
      state.messages = messages;
    },
    SET_MESSAGE_LOADING(state, loading: boolean) {
      state.messageLoading = loading;
    },
    SET_MESSAGE_FINISHED(state, finished: boolean) {
      state.messageFinished = finished;
    },
    SET_UNRAED_MESSAGES_LENGTH(state, unreadMessagesLength: number) {
      state.unreadMessagesLength = unreadMessagesLength;
    },
    SET_ROOMS(state, rooms: Room[]) {
      state.rooms = rooms;
    },
    SET_ROOM_LOADED(state, loaded: boolean) {
      state.roomLoaded = loaded;
    },
    SET_IS_ANDROID(state, isAndroid: boolean) {
      state.isAndroid = isAndroid;
    },
    SIGN_OUT(state) {
      state.redirect = "/";
      state.isInLiff = false;
      state.isInMingakuStudentApp = false;
      state.role = null;
      state.student = null;
      state.classroomDocId = "";
      state.schoolDocId = "";
      state.learnings = [];
      state.learningLoading = false;
      state.learningFinished = false;
      state.learningPageType = "none";
      state.learning = null;
      state.messageStream = null;
      state.messages = [];
      state.messageLoading = false;
      state.messageFinished = false;
      state.rooms = [];
      state.roomLoaded = false;
    }
  },
  actions: {
    async setMessageStream(context) {
      if (!context.state.student) {
        return;
      }
      context.commit("SET_MESSAGE_FINISHED", false);

      if (context.state.messageStream !== null) {
        context.state.messageStream();
      }

      context.commit("SET_MESSAGES", []);
      const stream = getMessageStream(context.state.student.ref, 1).onSnapshot(
        async snapshot => {
          snapshot.docChanges().forEach(change => {
            const currentMessages = context.state.messages;
            if (change.type === "added") {
              if (
                !!currentMessages[0] &&
                currentMessages[0].ref.id === change.doc.id
              ) {
                return;
              }
              if (change.doc.data().type !== "multiple") {
                const changedMessage = convertToMessage(
                  change.doc.data(),
                  change.doc.ref
                );
                if (
                  !isMessageToIgnoreForStudent(changedMessage.data.from.name)
                ) {
                  currentMessages.push(changedMessage);
                  currentMessages.sort(
                    (a, b) => b.data.timestamp - a.data.timestamp
                  );
                }
              } else {
                change.doc
                  .data()
                  .messageContents.forEach((c: MessageContent) => {
                    currentMessages.push(
                      convertToMessage(
                        {
                          ...change.doc.data(),
                          messageText: c.text,
                          type: c.type
                        },
                        change.doc.ref
                      )
                    );
                  });
                currentMessages.sort(
                  (a, b) => b.data.timestamp - a.data.timestamp
                );
              }
            }
            context.commit("SET_MESSAGES", currentMessages);
          });
          await context.dispatch("getUnreadMessagesLength");
        }
      );
      context.commit("SET_MESSAGE_STREAM", stream);

      let messages: Message[];
      try {
        messages = await getMessages(context.state.student.ref, messageLimit);
      } catch (e) {
        alert("メッセージの取得に失敗しました");
        return;
      }

      const newMessages = context.state.messages;
      messages.forEach(message => {
        const messageIdsTypes = newMessages.map(m => m.ref.id + m.data.type);
        if (
          !messageIdsTypes.includes(message.ref.id + message.data.type) &&
          !isMessageToIgnoreForStudent(message.data.from.name)
        ) {
          newMessages.push(message);
        }
      });
      newMessages.sort((a, b) => b.data.timestamp - a.data.timestamp);
      context.commit("SET_MESSAGES", newMessages);
      if (messages.length < messageLimit) {
        context.commit("SET_MESSAGE_FINISHED", true);
      }
    },
    async getMoreMessage(context) {
      if (
        context.state.messageFinished ||
        context.state.messageLoading ||
        !context.state.student ||
        context.state.messages.length === 0
      ) {
        return;
      }

      const lastMessage =
        context.state.messages[context.state.messages.length - 1];
      context.commit("SET_MESSAGE_LOADING", true);
      let messages: Message[];
      try {
        messages = await getMessages(
          context.state.student.ref,
          messageLimit,
          lastMessage.ref
        );
      } catch (e) {
        alert("メッセージの取得に失敗しました");
        context.commit("SET_MESSAGE_LOADING", false);
        return;
      }
      const newMessages = context.state.messages;
      messages.forEach(message => {
        const messageIds = newMessages.map(m => m.ref.id);
        if (
          !messageIds.includes(message.ref.id) &&
          !isMessageToIgnoreForStudent(message.data.from.name)
        ) {
          newMessages.push(message);
        }
      });
      newMessages.sort((a, b) => b.data.timestamp - a.data.timestamp);
      context.commit("SET_MESSAGES", newMessages);
      if (messages.length < messageLimit) {
        context.commit("SET_MESSAGE_FINISHED", true);
      }
      context.commit("SET_MESSAGE_LOADING", false);
    },
    async getUnreadMessagesLength(context) {
      if (!context.state.student) {
        return;
      }
      try {
        const unreadMessagesLength: number = await getUnreadMessagesLength(
          context.state.student.ref
        );
        context.commit("SET_UNRAED_MESSAGES_LENGTH", unreadMessagesLength);
      } catch (e) {
        // alert("未読のメッセージ件数の取得に失敗しました");
        console.log(e);
      }
    },
    async getSubmissions(context) {
      if (!context.state.student) {
        return;
      }
      try {
        // 提出可能で期限前の submission を取得
        const submissions = await getSubmissionOfStudent(
          context.state.student.ref,
          {
            submittableBefore: dayjs().unix()
          }
        );
        if (!submissions) return;

        const complementedSubmissions = await complementSubmissions(
          submissions
        );
        context.commit("SET_SUBMISSIONS", complementedSubmissions);
      } catch (e) {
        context.commit(
          "SET_SUBMISSIONS_ERROR",
          "提出物の情報が取得できませんでした。"
        );
      } finally {
        context.commit("SET_SUBMISSIONS_INITIALIZED", true);
      }
    },
    async getMiniApps(context) {
      if (!context.state.student) {
        console.log("Student not detected");
        return;
      }
      try {
        const student = context.state.student;
        const schoolRef = await getSchoolRefFromStudentRef(student.ref);
        const miniApps: MiniApp[] = await getMiniAppsOfSchool(schoolRef);
        const sortedminiApps = miniApps.sort((a, b) => {
          if (!a.data || !b.data) {
            return 0; // a.data または b.data が null の場合、その要素の順序は変更しない
          }
          return a.data.displayOrder - b.data.displayOrder;
        });
        context.commit("SET_APPS", sortedminiApps);
        context.dispatch("getminiAppimageUrls");
      } catch (e) {
        alert("アプリ情報の取得に失敗しました");
        console.log(e);
      }
    },
    async getminiAppimageUrls(context) {
      const miniApps = context.state.miniAppList;

      if (miniApps.length === 0) {
        return;
      }

      const imageUrlsPromises = miniApps.map(item => {
        if (item.data?.iconUrl) {
          return getImageUrl(item.data.iconUrl).then(url => {
            return { key: item.data?.iconUrl, url };
          });
        } else {
          return Promise.resolve(null);
        }
      });
      const imageUrlsResults = await Promise.all(imageUrlsPromises);
      const imageUrls: { [key: string]: string } = {};
      imageUrlsResults.forEach(result => {
        if (result && result.key && result.url.length > 0) {
          imageUrls[result.key] = result.url;
        }
      });

      context.commit("SET_MINIAPPIMAGEURLS", imageUrls);
    },
    async getThreads(context) {
      if (!context.state.student) {
        return;
      }
      try {
        // 提出可能で期限前の submission を取得
        const threads = await getThreadsOfStudent(context.state.student.ref, {
          submittableBefore: dayjs().unix()
        });
        if (!threads) return;
        const complementedThreads = await complementThreads(threads);
        context.commit("SET_THREADS", complementedThreads);
      } catch (e) {
        context.commit(
          "SET_THREADS_ERROR",
          "提出物の情報が取得できませんでした。"
        );
      } finally {
        context.commit("SET_THREADS_INITIALIZED", true);
      }
    },
    async getLearnings(context) {
      if (!context.state.student) {
        return;
      }

      const learnings = await getLearningsOf(
        context.state.student.ref,
        context.state.learningLimit
      );
      if (!learnings) {
        alert("学習履歴の取得に失敗しました");
        return;
      }
      if (learnings.length < learningLimit) {
        context.commit("SET_LEARNING_FINISHED", true);
      }
      context.commit("SET_LEARNINGS", learnings);
      context.commit("SET_LEARNING_LOADING", false);
    },
    async updateStudent(context) {
      if (!context.state.role) {
        return;
      }
      const student = await getStudent(context.state.role.data.ref);
      context.commit("SET_STUDENT", student);
    },
    async getMoreLearning(context) {
      if (
        context.state.learningFinished ||
        context.state.learningLoading ||
        !context.state.student ||
        context.state.learnings.length === 0
      ) {
        return;
      }

      const lastLearing =
        context.state.learnings[context.state.learnings.length - 1];
      context.commit("SET_LEARNING_LOADING", true);
      const learnings = await getLearningsOf(
        context.state.student.ref,
        learningLimit,
        lastLearing.ref
      );
      context.commit("SET_LEARNING_LOADING", false);
      if (!learnings) {
        alert("学習履歴の取得に失敗しました");
        return;
      }
      const newLearnings = [...context.state.learnings, ...learnings];
      if (learnings.length < learningLimit) {
        context.commit("SET_LEARNING_FINISHED", true);
      }
      context.commit("SET_LEARNINGS", newLearnings);
    },
    async getRooms(context) {
      if (!context.state.student) {
        return;
      }

      if (!context.state.schoolConfig) {
        return;
      }

      try {
        const docIds = getDocIdsOfStudent(context.state.student);
        const rooms = await fetchRooms(
          docIds[2],
          docIds[0],
          context.state.student.data.target.id,
          context.state.schoolConfig.data.hideMingakuStudyRoom ?? false
        );
        context.commit("SET_ROOMS", rooms);
        context.commit("SET_ROOM_LOADED", true);
      } catch (e) {
        console.error(e);
        alert(e);
      }
    },
    async signOut() {
      await signOut();
      router.go(0);
    }
  },
  modules: {}
});
export const awaitStudentLoaded = async (
  context: Store<AppState>
): Promise<Student> => {
  if (context.state.student) return context.state.student;
  await new Promise(resolve => setTimeout(resolve, 250));
  return await awaitStudentLoaded(context);
};
export const awaitLiffInitialized = async (
  context: Store<AppState>
): Promise<boolean> => {
  if (context.state.initLiff) return context.state.initLiff;
  await new Promise(resolve => setTimeout(resolve, 250));
  return await awaitLiffInitialized(context);
};
