import firebase from "firebase/app";

export const messageCollectionKey = "messages";

export type MessageType =
  | "text"
  | "image"
  | "video"
  | "audio"
  | "file"
  | "location"
  | "sticker"
  | "button_template"
  | "basic_button_template"
  | "confirm_template"
  | "multiple";

export type MessageUserType = "student" | "tutor" | "admin" | "system" | "bot";

export type Message = {
  ref: firebase.firestore.DocumentReference;
  data: MessageData;
};

export type MessageData = {
  from: MessageUser;
  type: MessageType;
  messageText?: string;
  messageContents?: MessageContent[];
  template?: ButtonTemplateMessage; // [deprecated]
  basicButtonTemplate?: BasicButtonTemplateMessage;
  confirmTemplate?: ConfirmTemplateMessage;
  lineId: string;
  timestamp: number;
  transfer?: boolean;
  checked?: boolean;
};

export type MessageUser = {
  type: MessageUserType;
  userId: string;
  name: string;
};

export type MessageContent = {
  type: MessageType;
  text: string;
  buttonTemplate?: BasicButtonTemplateMessage;
  confirmTemplate?: ConfirmTemplateMessage;
};

export type ButtonTemplateMessage = {
  altText: string;
  buttonLabel: string;
  buttonUri: string;
  title?: string;
  thumbnailUrl?: string;
};

export type BasicButtonTemplateMessage = {
  altText: string;
  title?: string;
  thumbnailUrl?: string;
  actions: TemplateMessageAction[]; // max-length: 4
};

export type ConfirmTemplateMessage = {
  altText: string;
  text: string;
  actions: [TemplateMessageAction, TemplateMessageAction];
};

export type TemplateMessageAction<
  ExtraFields = {
    label: string;
  }
> = (
  | PostbackAction
  | MessageAction
  | URIAction
  | {
      type: "camera";
    }
  | {
      type: "cameraRoll";
    }
  | {
      type: "location";
    }
) &
  ExtraFields;

export type PostbackAction = {
  type: "postback";
  data: string;
  displayText?: string;
};

export type MessageAction = {
  type: "message";
  text: string;
};

export type URIAction = {
  type: "uri";
  uri: string;
};

export type PostbackActionType = "end_learning" | "rest_learning";

export const actionEndLearning: PostbackActionType = "end_learning";
export const actionRestLearning: PostbackActionType = "rest_learning";
export const availableActionTypes: string[] = [
  actionEndLearning,
  actionRestLearning
];

export const messageTextsToAvoidNotification = ["> 休憩", "> 終了"];

// student の [schoolDocId, classroomDocId, studentDocId, messageDocId] を返す
export function getDocIdsOfMessageRef(
  ref: firebase.firestore.DocumentReference
): [string, string, string, string] {
  if (!ref.parent.parent) {
    return ["", "", "", ""];
  }
  const studentRef = ref.parent.parent;
  if (!studentRef.parent.parent) {
    return ["", "", "", ""];
  }
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef.parent.parent) {
    return ["", "", "", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [schoolRef.id, classroomRef.id, studentRef.id, ref.id];
}

export function convertToMessage(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Message {
  const returnData: MessageData = {
    from: data.from,
    type: data.type,
    messageText: data.messageText,
    template: data.template,
    lineId: data.lineId,
    timestamp: data.timestamp,
    transfer: data.transfer,
    checked: data.checked ?? true //checkedが存在しない → 既読とみなす
  };

  if (data.template) {
    returnData.template = data.template;
  }
  if (data.basicButtonTemplate) {
    returnData.basicButtonTemplate = data.basicButtonTemplate;
  }
  if (data.confirmTemplate) {
    returnData.confirmTemplate = data.confirmTemplate;
  }

  return {
    ref,
    data: returnData
  };
}
